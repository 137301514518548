import { createSelector } from '@reduxjs/toolkit';

const app = ( state: any ) => state.app
const appSelector = createSelector(app, app => app);

export const sideBarSelector:any = createSelector(
  [appSelector],
  app => app.isOpenSidebar
);

export const screenSizeSelector:any = createSelector(
  [appSelector],
  app => app.currentScreenSize
);

export const currentUserSelector:any = createSelector(
  [appSelector],
  app => app.currentUser
);

export const appThemeSelector:any = createSelector(
  [appSelector],
  app => app.appTheme
);

export const loadingPageSelector:any = createSelector(
  [appSelector],
  app => app.isLoadingPage
);

export const proviceOptionsSelector:any = createSelector(
  [appSelector],
  app => app.proviceOptions
);
