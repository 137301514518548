import Loader from 'components/Loader';


const Houses = Loader(() => import("modules/Houses"));
const HouseDetail = Loader(() => import("modules/Houses/HouseDetail"));

export const housePath = [
  {
    path: 'houses',
    element: <Houses />,
    active: true,
    module: "REAL_ESTATE",
    permissions: ["VIEW"]
  },
  {
    path: 'houses/detail',
    element: <HouseDetail />,
    active: true,
    module: "REAL_ESTATE",
    permissions: ["VIEW"]
  },
];
