import { MenuItems } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import DashboardIcon from '@mui/icons-material/Dashboard';

const dashBoardMenu: MenuItems[] = [
    {
      id: '0',
      heading: 'dashboard',
      module: "user",
      permissions: ["MENU"],
      items: [
        {
          id: '0_1',
          parent: '0',
          name: 'dashboard',
          link: '/dashboard',
          icon: DashboardIcon,
          module: "user",
          permissions: ["MENU"],
        }
      ],
    }
  ];
  
  export default dashBoardMenu;
  