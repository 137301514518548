import { Module } from "../models/user";

export const checkPermissons = (user: any, module: string, actions: Array<string>): boolean => {
    let canAccess = false;

    // get list permissions of current user
    const role = user?.role;
    if (!role) {
        return false;
    }
    // check modules
    const getModuleNeedCheck = role.modules.find((item: Module) => item.moduleCode === module) ;
    if (!getModuleNeedCheck) {
        return false;
    }

    canAccess = getModuleNeedCheck.permissions.some((item: any) => actions.includes(item));

    return canAccess;
  }