import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { CONFIG_APP } from "../configs/common";
import { actions as actionAuth } from "../store/auth";
import { actions as actionApp } from "../store/app";

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

const instance = axios.create({
  baseURL: CONFIG_APP.API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  timeout: 100000,
});

instance.interceptors.request.use(
  async (config) => {
    store.dispatch(actionApp.callLoadingPage(true));
    const idToken: string = localStorage.getItem("accessToken");
    const common: any = {
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${idToken}`,
      ...config.headers,
      //more settings
    };

    config.headers = common;
    return config;
  },
  (error) => {
    store.dispatch(actionApp.callLoadingPage(false));
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    store.dispatch(actionApp.callLoadingPage(false));
    if (res.data) {
      return res;
    } else {
      return Promise.reject(null);
    }
  },
  async (error) => {
    store.dispatch(actionApp.callLoadingPage(false));
    // const originalRequest = error.config;
    // Check error;
    // if (error.response.status === 400) {
    //   toast.error(error.response?.data?.error?.message);
    //   throw;
    // }
    // if 403 => show k có quyền
    if (error.response?.status === 403) {
      toast.error("Không có quyền truy cập");
    }
    // if 500 => show lỗi hệ thống
    if (error.response?.status === 500 || !error.response?.status ) {
      toast.error("Hệ thống tạm thời bị gián đoạn");
    }
    // if 401 => show chưa đăng nhập
    if (error.response?.status === 401) {
      toast.error("Phiên làm việc đã hết hạn");
      store.dispatch(actionAuth.logout({}));
    }
    return Promise.reject(error);
  }
);

export default instance;
