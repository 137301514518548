import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "constants/common";

const auth = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP);
// const info = localStorage.getItem(LOCAL_STORAGE_KEY.ACCOUNT_INFO);

const initialState = {
    isLoggedIn: auth ? true : false,
    // accountInfo: info ? {data: info } : {},
    isError: false,
    message: 'The application is fetching data, please wait a few minutes....'
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginFulfilled: (state, action) => ({
            ...state,
            isLoggedIn : true,
            accountInfo: action.payload.result,
            message: ""
        }),
        loginRejected: (state, action) => ({
            ...state,
            isLoggedIn : false,
            isError: true,
            accountInfo : null,
            message: action.payload || 'An error occurred while retrieving data, please contact the administrator'
        }),
        logoutFulfilled: (state, action) => ({
            ...state,
            isLoggedIn : false,
        }),
        clearState: (state) => ({
            ...state,
            isLoggedIn: false,
            isError: false,
            accountInfo: null,
            message: ""
        })
    }
});

export default authSlice;
