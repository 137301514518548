import catalogService from '../../services/catalog.service';
import userService from '../../services/user.service';
import createOperation from '../createOperation';
import slice from './slice';
const { actions } = slice;

export const actionExtra = createOperation({
  actions: {
    successAction: null,
  },
  async process() {
    return null;
  },
});

export const getCurrentUser = createOperation({
  actions: {
    successAction: actions.getCurrentUserFull,
  },
  async process() {
    return await userService.getMe();
  },
});

export const changeTheme = createOperation({
  actions: {
    successAction: actions.changeThemeSuccessed,
  },
  async process(value) {
    localStorage.setItem('appTheme', value?.payload);
    return value?.payload;
  },
});

export const callLoadingPage = createOperation({
  actions: {
    successAction: actions.callLoadingSuccessed,
  },
  async process(value) {
    return value?.payload;
  },
});

export const getProviceOptions = createOperation({
  actions: {
    successAction: actions.getProviceOptionsSuccessed,
  },
  async process(value) {
    return await catalogService.getAllProvince();
  },
});