export const themeColors = {
    primary: '#359aff',
    secondary: '#6E759F',
    success: '#44D600',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#101418',
    white: '#ffffff',
    primaryAlt: '#000C57'
};