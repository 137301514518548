import SidebarLayout from "layouts/SidebarLayout";
import BaseLayout from "layouts/BaseLayout";

import { Navigate } from "react-router";
import { userPath } from "modules/Users/configs/path";
import Loader from "components/Loader";
import { dashboardPath } from "modules/Dashboard/configs/path";
import { statusPath } from "modules/Status/configs/path";
import { componentPath } from "modules/ComponentsPage/configs/path";
import { housePath } from "modules/Houses/configs/path";

//Auth
const Login = Loader(() => import("modules/Login"));

const routes = [
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      ...statusPath,
      {
        path: "*",
        element: <Navigate to={"/houses"} />,
      },
      {
        path: "",
        element: <Navigate to={"/houses"} />,
      },
    ],
  },
  //rnf
  {
    path: "login",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "home",
        element: <>Home page test 3</>,
      },
      {
        path: "/components",
        element: <>Home page test 3</>,
      },
      ...userPath,
      ...housePath,
      ...dashboardPath,
      ...componentPath,
    ],
  },
];

export default routes;
