import axios from "axios";
import { LOCAL_STORAGE_KEY } from "constants/common";
import { CONFIG_APP } from "../configs/common";
import { toast } from "react-toastify";

const API_URL = CONFIG_APP.API_URL;

const login = (body: any): any => {
  return axios
    .post(`${API_URL}auth/login`, body)
    .then((response: any) => {
      if (response.data?.statusCode === 400) {
        toast.error(response.data?.message);
        return Promise.reject(response?.message);
      }
      if (response.data.accessToken) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP,
          response.data.accessToken
        );
      }
      return response.data.accessToken;
    })
    .catch((err) => {
      toast.error(err.response?.data?.message);
      return Promise.reject(err);
    });
};

const logout = async () => {
  const idToken: string = localStorage.getItem(
    LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP
  );
  return axios
    .post(
      `${API_URL}auth/logout`,
      {},
      { headers: { Authorization: `Bearer ${idToken}` } }
    )
    .then((response: any) => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP);
    })
    .catch((err) => {
      // toast.error(err.response?.data?.message);
      // return Promise.reject(err);
    }).finally(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP);
    });
};

const authService = {
  login,
  logout,
};

export default authService;
