import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { themeCreator } from "./base";
import { StylesProvider } from "@mui/styles";
import { selectors as selectorApp } from "../store/app";

const ThemeProviderWrapper: React.FC = (props) => {
  const appTheme: string = useSelector(selectorApp.appThemeSelector);
  const theme = themeCreator(appTheme);
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
