import { MenuItems } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import PersonIcon from '@mui/icons-material/Person';

const componentMenu: MenuItems[] = [
    {
      id: '0',
      heading: 'UI Element',
      module: "user",
      permissions: ["MENU"],
      items: [
        {
          id: '0_1',
          parent: '0',
          name: 'accordions',
          link: '/accordions',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_2',
          parent: '0',
          name: 'avatars',
          link: '/avatars',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_3',
          parent: '0',
          name: 'badges',
          link: '/badges',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_4',
          parent: '0',
          name: 'buttons',
          link: '/buttons',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_5',
          parent: '0',
          name: 'cards',
          link: '/cards',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_6',
          parent: '0',
          name: 'forms',
          link: '/forms',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_7',
          parent: '0',
          name: 'modals',
          link: '/modals',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_8',
          parent: '0',
          name: 'tabs',
          link: '/tabs',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        },
        {
          id: '0_9',
          parent: '0',
          name: 'tooltips',
          link: '/tooltips',
          icon: PersonIcon,
          module: "user",
          permissions: ["MENU"],
        }
      ],
    }
  ];
  
  export default componentMenu;
  