import Loader from 'components/Loader';

const Accordions = Loader(() => import("modules/ComponentsPage/Accordions"));
const Avatars = Loader(() => import("modules/ComponentsPage/Avatars"));
const Badges = Loader(() => import("modules/ComponentsPage/Badges"));
const Buttons = Loader(() => import("modules/ComponentsPage/Buttons"));
const Cards = Loader(() => import("modules/ComponentsPage/Cards"));
const Forms = Loader(() => import("modules/ComponentsPage/Forms"));
const Modals = Loader(() => import("modules/ComponentsPage/Modals"));
const Tabs = Loader(() => import("modules/ComponentsPage/Tabs"));
const Tooltips = Loader(() => import("modules/ComponentsPage/Tooltips"));

export const componentPath = [
  {
    path: 'accordions',
    element: <Accordions />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'avatars',
    element: <Avatars />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'badges',
    element: <Badges />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'buttons',
    element: <Buttons />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'cards',
    element: <Cards />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'forms',
    element: <Forms />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'modals',
    element: <Modals />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'tabs',
    element: <Tabs />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
  {
    path: 'tooltips',
    element: <Tooltips />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
];
