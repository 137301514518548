
import { Formik, FormikProps, Form } from "formik";

const FormFmk = ({ children, initValue, enableReinitialize, validationSchema, onSubmit, ...props }) => {
  return (
    <Formik initialValues={initValue} enableReinitialize={enableReinitialize} onSubmit={onSubmit} validationSchema={validationSchema} {...props}>
      {(props: FormikProps<{}>) => <Form>{children}</Form>}
    </Formik>
  );
};
export default FormFmk;
