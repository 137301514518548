import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import component
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from '@mui/icons-material/Person';

//import store
import { actions as actionAuth } from "../../../../store/auth";
import { selectors as selectorApp } from "../../../../store/app";
import { useTranslation } from "react-i18next";
import { User } from "../../../../models/user";
import ChangePassword from "../../../../content/ChangePassword";
import { ROLE } from "../../../../constants/common";
import { stringAvatar } from "../../../../utils/common";
import EditProfile from "content/Setting";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openChangePass, setOpenChangePass] = useState(false);
  const currentUser: User = useSelector(selectorApp.currentUserSelector);

  const handleClickOpenSetting = () => {
    setOpenSetting(true);
  };

  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = () => {
    dispatch(actionAuth.logout(null));
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          {...stringAvatar(currentUser?.fullName)}
          src={currentUser?.avatar}
        />
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            {...stringAvatar(currentUser?.fullName)}
            src={currentUser?.avatar}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">{currentUser?.fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {ROLE[currentUser?.role?.roleCode]?.label}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton onClick={handleClickOpenSetting}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("common.personally")} />
          </ListItemButton>
          <ListItemButton onClick={() => setOpenChangePass(true)}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <PasswordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={"Đổi mật khẩu"} />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={logout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t("common.logout")}
          </Button>
        </Box>
        <EditProfile
          open={openSetting}
          handleClose={handleCloseSetting}
          userInfo={currentUser}
        />
        <ChangePassword
          open={openChangePass}
          onClose={() => setOpenChangePass(false)}
        />
      </Popover>
    </>
  );
}

export default HeaderUserbox;
