/**
 * description: This file defines the common constants used in the application
 * LANGS, STATE_APP
 * TYPE_LOCAL_STORAGE, RESTART_POLICY
 * DEVICE_STATUS_MESSAGE, REQUEST_MESSAGE
 * KEY_DEPLOY_STATUS, VIEW_DELOY_STATUS
 * LOCAL_STORAGE_KEY
 */

export const LANGS = [
  {
    value: "vi",
    name: "common.vietnamese",
  },
  {
    value: "en",
    name: "common.english",
  },
];

export const BREAK_POINT_SCREEN = 1279;

export const STATE_APP = {
  MAINTAIN: "maintain",
  RUNNING: "running",
};

export const TYPE_LOCAL_STORAGE = {
  URL_REDIRECT: "url-redirect",
};

export const LOCAL_STORAGE_KEY = {
  APP_THEME: "appTheme",
  ACCESS_TOKEN_APP: "accessToken",
  ACCESS_TOKEN_REGISTRY: "accessTokenRegistry",
  ACCOUNT_INFO: "info",
  CURRENT_HUB: "currentHub",
  HUB_INFO: "hubInfo",
  HUB_KEY_NAME: "keyName",
  HUB_PRIMARY_KEY: "primaryKey",
  DEVICE_DATA: "deviceData",
  MODULE_DATA: "moduleData",
};

export const DIRECTION_HOUSE = [
  { value: "DONG", label: "Đông" },
  { value: "TAY", label: "Tây" },
  { value: "NAM", label: "Nam" },
  { value: "BAC", label: "Bắc" },
  { value: "TAY_BAC", label: "Tây Bắc" },
  { value: "TAY_NAM", label: "Tây Nam" },
  { value: "DONG_BAC", label: "Đông Bắc" },
  { value: "DONG_NAM", label: "Đông Nam" },
];

export const TYPE_HOUSE = [
  { value: "CC", label: "Chung cư" },
  { value: "BT", label: "Biệt thự" },
  { value: "NHA", label: "Nhà" },
  { value: "DAT", label: "Đất" },
  { value: "LK", label: "Liền kề" },
];

export const STATUS_REAL = {
  SELLING: {
    label: "Đang bán",
    color: "info",
    code: 'SELLING'
  },
  URGENTLY_SELL: {
    label: "Cần bán gấp",
    color: "error",
    code: 'URGENTLY_SELL'
  },
  SOLD: {
    label: "Đã bán",
    color: "success",
    code: 'SOLD'
  },
  STOP_SELL: {
    label: "Dừng bán",
    color: "default",
    code: 'STOP_SELL'
  },
  DEPOSIT: {
    label: "Đã đặt cọc",
    color: "warning",
    code: 'DEPOSIT'
  },
};

export const ROLE = {
  ADMIN: {
    code: "ADMIN",
    label: "Quản trị",
  },
  MANAGER: {
    code: "MANAGER",
    label: "Quản lý",
  },
  SELLER: {
    code: "SELLER",
    label: "Đối tác",
  },
  EMPLOYEE: {
    code: "EMPLOYEE",
    label: "Nhân viên",
  },
};
export const STATUS_OPTIONS = [
  { value: STATUS_REAL.SELLING.code, label: STATUS_REAL.SELLING.label },
  { value: STATUS_REAL.URGENTLY_SELL.code, label: STATUS_REAL.URGENTLY_SELL.label },
  { value: STATUS_REAL.DEPOSIT.code, label: STATUS_REAL.DEPOSIT.label},
  { value: STATUS_REAL.SOLD.code, label: STATUS_REAL.SOLD.label},
  { value: STATUS_REAL.STOP_SELL.code, label: STATUS_REAL.STOP_SELL.label},
];

export const ROLE_OPTIONS = [
  { value: ROLE.ADMIN.code, label: ROLE.ADMIN.label },
  { value: ROLE.MANAGER.code, label: ROLE.MANAGER.label },
  { value: ROLE.SELLER.code, label: ROLE.SELLER.label },
  { value: ROLE.EMPLOYEE.code, label: ROLE.EMPLOYEE.label },
];

export const RANGE_PRICE = [
  { value: "- 3", label: "Dưới 3 tỷ" },
  { value: "3 - 6", label: "3 - 6 tỷ" },
  { value: "6 - 10", label: "6 - 10 tỷ" },
  { value: "10 - 20", label: "10 - 20 tỷ" },
  { value: "20 - 50", label: "20 - 50 tỷ" },
  { value: "50 - ", label: "Trên 50 tỷ" },
];

export const RANGE_AREA = [
  {value: "- 30", label: "Dưới 30 m2"},
  {value: "30 - 50", label: "30 - 50 m2"},
  {value: "50 - 80", label: "50 - 80 m2"},
  {value: "80 - 100", label: "80 - 100 m2"},
  {value: "100 - 150", label: "100 - 150 m2"},
  {value: "150 - 200", label: "150 - 200 m2"},
  {value: "200 - 250", label: "200 - 250 m2"},
  {value: "250 - 300", label: "250 - 300 m2"},
  {value: "300 - 500", label: "300 - 500 m2"},
  {value: "500 -", label: "Trên 500 m2"},
]
