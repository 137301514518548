import { MenuItems } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import ErrorIcon from '@mui/icons-material/Error';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const statusMenu: MenuItems[] = [
    {
      id: '0',
      heading: '',
      module: "user",
      permissions: ["MENU"],
      items: [
        {
          id: '0_1',
          parent: '0',
          name: 'status',
          link: '/status',
          icon: ErrorIcon,
          module: "user",
          permissions: ["MENU"],
          items: [
            {
              id: '0_1_1',
              parent: '0_1',
              name: '404',
              link: '/404',
              icon: FiberManualRecordIcon,
              module: "user",
              permissions: ["MENU"],
            
            },
            {
              id: '0_1_2',
              parent: '0_1',
              name: '500',
              link: '/500',
              icon: FiberManualRecordIcon,
              module: "user",
              permissions: ["MENU"],
            
            },
            {
              id: '0_1_3',
              parent: '0_1',
              name: 'comingSoon',
              link: '/coming-soon',
              icon: FiberManualRecordIcon,
              module: "user",
              permissions: ["MENU"],
            
            },
            {
              id: '0_1_4',
              parent: '0_1',
              name: 'maintenance',
              link: '/maintenance',
              icon: FiberManualRecordIcon,
              module: "user",
              permissions: ["MENU"],
            
            },
          ]
        }
      ],
    }
  ];
  
  export default statusMenu;
  