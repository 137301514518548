import { useField, FieldHookConfig } from "formik";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const TextFieldFmik = (props: TextFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(true);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(false);
  };

  return (
    <>
      <TextField
        {...field}
        {...props}
        id={props.name}
        type={showPassword ? "text" : props.type}
        label={props.label}
        name={props.name}
        size="small"
        fullWidth
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        InputLabelProps={{ shrink: field.value ? true : false }}
        InputProps={
          props.type === "password" && !props.disabled
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
      />
    </>
  );
};

export default TextFieldFmik;
