import axios from "./abstract.service";
import { toast } from "react-toastify";

const uploadFileAction = (files: any): any => {
  const formData = new FormData();
  Array.from(files).forEach((item: any) => {
    formData.append("files", item);
  });

  return axios
    .post(`files/upload`, formData)
    .then((response: any) => {
      return response.data?.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Tải file lên thất bại");
      }
      return Promise.reject(err);
    });
};

const deleteFile = (id: any): any => {
  return axios
    .delete(`files/${id}`)
    .then((response: any) => {
      if (!response.data) {
        toast.error("Xoá file thất bại");
        return null;
      }

      toast.success("Xoá file thành công");
      return response.data?.data.id;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Xoá file thất bại");
      }
      return Promise.reject(err);
    });
};

const uploadService = {
  uploadFileAction,
  deleteFile,
};

export default uploadService;
