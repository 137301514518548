import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { selectors as selectorApp, actions as actionApp } from "../store/app";
import { selectors as selectorAuth } from "../store/auth";

const withAuth = (WrappedComponent: any) => {
  const WithAuth = (props: any) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectorApp.currentUserSelector);
    const isLoggedIn = useSelector(selectorAuth.userSelector);

    useEffect(() => {
      dispatch(actionApp.getCurrentUser({}));
    }, [dispatch]);

    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }

    if (!currentUser) {
      return <></>;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

export default withAuth;
