import Loader from 'components/Loader';


const Users = Loader(() => import("modules/Users"));

export const userPath = [
  {
    path: 'users',
    element: <Users />,
    active: true,
    module: "USER",
    permissions: ["VIEW"]
  },
];
