import Loader from 'components/Loader';


const Dashboard = Loader(() => import("modules/Dashboard"));

export const dashboardPath = [
  {
    path: 'dashboard',
    element: <Dashboard />,
    active: true,
    module: "user",
    permissions: ["VIEW"]
  },
];
