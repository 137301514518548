import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'contexts/SidebarContext';

import PropTypes from 'prop-types';
import { Button, Badge, Collapse, ListItem, Tooltip, Typography } from '@mui/material';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { selectors as selectorApp } from 'store/app';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  role?: []
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  role,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);
  const { t } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Tooltip title={t(`trans_menu.${name}`)} arrow disableHoverListener={isOpenSidebar} placement="right">
          <Button
            className={clsx({ 'Mui-active': menuToggle })}
            startIcon={Icon && <Icon />}
            endIcon={
              isOpenSidebar ? (menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />) : null
            }
            onClick={toggleMenu}
          >
            <Typography variant="body1" component="h1" sx={{ whiteSpace: 'nowrap', fontWeight: 550, width: '80%', textAlign: 'start' }}>
              {isOpenSidebar ? t(`trans_menu.${name}`) : null}
            </Typography>
          </Button>
        </Tooltip>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Tooltip title={t(`trans_menu.${name}`)} arrow disableHoverListener={isOpenSidebar} placement="right">
        <Button
          className={active ? "Mui-active" : ""}
          component={RouterLink}
          onClick={toggleSidebar}
          to={link}
          startIcon={Icon && <Icon />}
        >
          <Typography variant="body1" component="span" sx={{ whiteSpace: 'nowrap', fontWeight: 550, textAlign: 'start' }}>
            {isOpenSidebar ? t(`trans_menu.${name}`) : null}
            {badge && <Badge badgeContent={badge} />}
          </Typography>
        </Button>
      </Tooltip>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  role: PropTypes.any
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
