import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "./router/router";
import { CssBaseline } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ThemeProvider from "./theme/ThemeProvider";
import { actions as actionApp, selectors as selectorApp } from "./store/app";
import LoadingIntercepTor from "./components/Loading";

const App = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes);
  const currScreenSize = useSelector(selectorApp.screenSizeSelector);

  const detectSize = () => {
    dispatch(
      actionApp.setScreenSize({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      })
    );
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <LoadingIntercepTor />
        <ToastContainer
          theme="colored"
          transition={Zoom}
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {content}
      </LocalizationProvider>
     
    </ThemeProvider>
  );
};
export default App;
