import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from 'components/Footer';
import { GuardAuth } from 'router/guard';

import { selectors as selectorApp  } from 'store/app';
import { BREAK_POINT_SCREEN } from 'constants/common';
import withAuth from 'hoc/withAuth';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<any>(({ theme, open, screenSize }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingLeft: screenSize.winWidth > BREAK_POINT_SCREEN ? theme.sidebar.width : 'unset',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    paddingLeft: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(100% - ${theme.sidebar.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        @media (min-width: 1280px) {
          max-width: unset;
      }
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);
  const currScreenSize = useSelector(selectorApp.screenSizeSelector);

  return (
    <>
      <GuardAuth>
        <Sidebar />
        <MainWrapper open={!isOpenSidebar} screenSize={currScreenSize}>
          <Header />
          <MainContent>
            <Outlet />
          </MainContent>
          <Footer />
        </MainWrapper>
      </GuardAuth>
    </>
  );
};

export default withAuth(SidebarLayout);
