import axios from "./abstract.service";

const getAllProvince = (): any => {
  return axios
    .get(`provinces`)
    .then((response: any) => {
      return response.data.map((item: any) => {
        return {
          value: item.provinceCode,
          label: item.name
        }
      });
    })
    .catch((err) => {
      return [];
    });
};
const getDistrictByProvice = (proviceCode: string): any => {
  return axios
    .get(`provinces/${proviceCode}/districts`)
    .then((response: any) => {
      return response.data.map((item: any) => {
        return {
          value: item.districtCode,
          label: item.name
        }
      });
    })
    .catch((err) => {
      return [];
    });
};
const getWardByDistrict = (districtCode: string): any => {
  return axios
    .get(`districts/${districtCode}/wards`)
    .then((response: any) => {
      return response.data.map((item: any) => {
        return {
          value: item.wardCode,
          label: item.name
        }
      });
    })
    .catch((err) => {
      return [];
    });
};
const catalogService = {
  getAllProvince,
  getDistrictByProvice,
  getWardByDistrict,
};

export default catalogService;
