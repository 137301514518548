import { MenuItems } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import PersonIcon from '@mui/icons-material/Person';

const userMenu: MenuItems[] = [
    {
      id: '0',
      heading: '',
      module: "USER",
      permissions: ["MENU"],
      items: [
        {
          id: '0_1',
          parent: '0',
          name: 'users',
          link: '/users',
          icon: PersonIcon,
          module: "USER",
          permissions: ["MENU"],
        }
      ],
    }
  ];
  
  export default userMenu;
  