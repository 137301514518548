import { createSlice } from '@reduxjs/toolkit';
import { BREAK_POINT_SCREEN, LOCAL_STORAGE_KEY } from 'constants/common';

const appTheme = localStorage.getItem(LOCAL_STORAGE_KEY.APP_THEME);
const initialState = {
    isOpenSidebar: true,
    currentUser: null,
    currentScreenSize:{ winWidth: window.innerWidth, winHeight: window.innerHeight},
    appTheme: appTheme || 'PureLightTheme',
    isLoadingPage: false,
    proviceOptions: [],
}

export default createSlice({
    name: 'app',
    initialState,
    reducers: {
        getCurrentUserFull: (state, action) => ({
            ...state,
            currentUser: action.payload.result
        }),
        toggleCollapseSideBar: (state) => ({
            ...state,
            isOpenSidebar: !state.isOpenSidebar
        }),
        setScreenSize: (state, action) => ({
            ...state,
            currentScreenSize: action.payload,
            isOpenSidebar: action.payload.winWidth < BREAK_POINT_SCREEN && !state.isOpenSidebar ?  true : state.isOpenSidebar 
        }),
        changeThemeSuccessed: (state,action) => ({
            ...state,
            appTheme: action.payload.result
        }),
        callLoadingSuccessed: (state,action) => ({
            ...state,
            isLoadingPage: action.payload.result
        }),
        getProviceOptionsSuccessed: (state,action) => ({
            ...state,
            proviceOptions: action.payload.result
        }),
    }
});