import { alpha, lighten, darken } from '@mui/material';
import { themeColors  } from './themeColors';

export const baseColors = {
    gradients: {
      blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
      blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
      blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
      orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
      orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
      purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
      pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
      pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
      green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
      black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)'
    },
    shadows: {
      success:
        '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
      error:
        '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
      info:
        '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
      primary:
        '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
      warning:
        '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
      card:
        '0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
      cardSm:
        '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
      cardLg:
        '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)'
    },
    layout: {
      general: {
        bodyBg: themeColors.black,
      },
      sidebar: {
        darkBackground: themeColors.black,
        textColor: themeColors.white,
        dividerBg: alpha(themeColors.white, 0.1),
        menuItemColor: themeColors.white,
        menuItemColorActive: themeColors.primary,
        menuItemBg: 'transparent',
        menuItemBgActive: alpha(themeColors.white, 0.1),
        menuItemIconColor: lighten(themeColors.secondary, 0.3),
        menuItemIconColorActive: themeColors.primary,
        menuItemHeadingColor: darken(themeColors.secondary, 0.3),
      }
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      trueWhite: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black
      }
    },
    secondary: {
      lighter: lighten(themeColors.secondary, 0.85),
      light: lighten(themeColors.secondary, 0.25),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2)
    },
    primary: {
      lighter: lighten(themeColors.primary, 0.85),
      light: lighten(themeColors.primary, 0.3),
      main: themeColors.primary,
      dark: darken(themeColors.primary, 0.2)
    },
    success: {
      lighter: lighten(themeColors.success, 0.85),
      light: lighten(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      lighter: lighten(themeColors.warning, 0.85),
      light: lighten(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      lighter: lighten(themeColors.error, 0.85),
      light: lighten(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      lighter: lighten(themeColors.info, 0.85),
      light: lighten(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    }
  };
  