import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CONFIG_APP } from "../../configs/common";

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: 0;
        margin: ${theme.spacing(1)} 0;
`
);

function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth="xl">
        <Box
          display={{ xs: "block", md: "flex" }}
          alignItems="center"
          textAlign={{ xs: "center", md: "left" }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1">
              &copy; {CONFIG_APP.COMPANY_NAME || "Admin"} {new Date().getFullYear()} - phiên bản {CONFIG_APP.VERSION_APP}
            </Typography>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
