import { List, ListSubheader } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import menuItems, { MenuItem } from "./items";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectors as selectorApp } from "../../../../store/app";
import { checkPermissons } from "../../../../utils/auth";

//Styles
const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;
      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 6px;
        flex-wrap: nowrap;
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1.5)};
            margin-left: 0;
            color: ${theme.sidebar.menuItemIconColor};
            width: 8%;
          }
          
          .MuiButton-endIcon {
            margin-left: ${theme.spacing(1.5)};
            width: 8%;
            font-size: ${theme.typography.pxToRem(18)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }
        .MuiCollapse-root {
          width: 100%;
          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }
          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

// Render menu
const renderSidebarMenuItems = ({
  items,
  path,
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item,
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const currentUser: boolean = useSelector(selectorApp.currentUserSelector);
  const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);

  // const { t } = useTranslation();
  /**
   * @nameF Lấy danh sách menu theo quyền của user
   * @description sử dụng reduce và đệ quy để lọc menu
   * @param {[]} arr list menu raw
   * @param {any} role quyền của người dùng
   * @return listMenuByPermission
   */
  const getMenuByPermission = (arr: any[], role: any) => {
    return arr.reduce((a: any, o: any) => {
      if (checkPermissons(currentUser, o.module, o.permissions)) {
        const _o = o?.items?.length
          ? { ...o, items: getMenuByPermission(o.items, role) }
          : { ...o };
        a.push(_o);
      }
      return a;
    }, []);
  };

  const menu = getMenuByPermission(menuItems, "admin");

  return (
    <>
      {menu.map((section: any, index: number) => (
        <MenuWrapper
          key={`er${index}`}
          subheader={
           (isOpenSidebar && section.heading) ? <ListSubheader component="div" disableSticky>{section.heading}</ListSubheader> : ''
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname,
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
