import { baseColors } from "./baseColors";

export const sidebar = {
  background: baseColors.layout.sidebar.darkBackground,
  textColor: baseColors.layout.sidebar.textColor,
  dividerBg: baseColors.layout.sidebar.dividerBg,
  menuItemColor: baseColors.layout.sidebar.menuItemColor,
  menuItemColorActive: baseColors.layout.sidebar.menuItemColorActive,
  menuItemBg: baseColors.layout.sidebar.menuItemBg,
  menuItemBgActive: baseColors.layout.sidebar.menuItemBgActive,
  menuItemIconColor: baseColors.layout.sidebar.menuItemIconColor,
  menuItemIconColorActive: baseColors.layout.sidebar.menuItemIconColorActive,
  menuItemHeadingColor: baseColors.layout.sidebar.menuItemHeadingColor,
  boxShadow:
    "0px 9px 16px rgb(51 51 54 / 26%), 0px 2px 2px rgb(62 62 65 / 32%)",
  width: "280px",
};
