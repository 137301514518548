import _ from 'lodash';
import format from 'date-fns/format';

export const formatQuery = (query: any) => {
  const formatted = {};

  _.forEach(query, (v, k) => {
    formatted[k] = v;
  });
  return formatted;
};

export function convertVietnamese(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export const convertStringDDMMYYYYToDate = (str: string) => {
  return format(
    new Date(str.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')),
    'dd/MM/yyyy',
  );
};

export const getRequestConfigurations = () => {
  return { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
}

/**
 * Description: This function to get and set data to local or session storage in browers
 * @param {string} key description: 
 * @param {any} val description:
 * @param {local | session} type description:  
*/
export const storage = (key: string, val?: any, type?: 'localStorage' | 'sessionStorage'): any => {
  if (typeof key === "object") {
    Object.keys(key).map((k: string): any => storage(k, key[k], val));
  } else {
    // Get item
    if (val === undefined) {
      let data = localStorage.getItem(key) || sessionStorage.getItem(key);
      if (typeof data === "string") {
        if (["{}", "[]"].includes(data[0] + data[data.length - 1]) && !!data[1].match(/^[\d\]"[{]$/)) { // Check isJSON
          return JSON.parse(data);
        }
      }
      return data;
    }
    // Set item
    try {
      (type === "localStorage" ? localStorage : sessionStorage).setItem(key, typeof val === "string" ? val : JSON.stringify(val));
      return val;
    } catch (e) {
      if (e.toString().match("QuotaExceeded")) {
        console.log("SYSTEM", "storage_full");
      } else {
        console.log(e);
      }
    }
  }
}

/**
* Description: This function to remove item in storage
* @param {array} params description: 
* @return {array - boolean} description: 
*/
export const removeStorage = (params: any[] = []): boolean[] => {
  return params.map((v: string): any => {
    sessionStorage.removeItem(v);
    localStorage.removeItem(v);
    return true;
  });
}

/**
* Description: This function to check a string is md5
* @param {string} string description: 
* @return {boolean} description: 
*/
export const isMd5 = (string: string): boolean => {
  return !!string.match(/^[a-f0-9]{32}$/);
}

/**
* Description: This function to convert hex color to rgb color
* @param {string} hex description: 
* @return {boolean} description: 
*/
export const hexToRgb = (hex: string): any[] => {
  // If there is a # at the beginning of the text then remove #
  if (/^#/.test(hex)) {
    hex = hex.slice(1);
  }

  // don't is hex color
  if (hex.length !== 3 && hex.length !== 6) {
    return [0, 0, 0];
  }

  // convert to array
  let d = hex.split("");

  if (hex.length === 3) {
    d = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]];
  }

  return ['r', 'g', 'b'].map((v: string, i: number): number => parseInt([d[i], d[i + 1]].join(""), 16));
}

/**
* Description: This function to convert rgb color to hex color
* @param {string} rgb description: 
* @return {boolean} description: 
*/
export const rgbToHex = (rgb: string): string => {
  const r = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  return "#" + ['r', 'g', 'b'].map((v: string, i: number): string => parseInt(r[i + 1], 10).toString(16).slice(-2)).join("");
}

export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const compactMoney = (money: any) => {
  let moneyToNumber = Number(money);
  let afterText = '';
  let firstText: any = 0

  if (money.length >= 10) {
    afterText = 'tỷ';
    firstText = (moneyToNumber / 1000000000);
    return `${Math.round(firstText * 100) / 100} ${afterText}`
  }
  if (money.length >= 7) {
    afterText = 'triệu';
    firstText = (moneyToNumber / 1000000).toFixed(1);
    return `${Math.round(firstText * 100) / 100} ${afterText}`
  }
  if (money.length >= 4) {
    afterText = 'nghìn';
    firstText = (moneyToNumber / 1000);
    return `${Math.round(firstText * 100) / 100} ${afterText}`
  }

  return money;
}

export const stringAvatar = (name: string) => {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}