import { Suspense, lazy } from "react";
import SuspenseLoader from "components/SuspenseLoader";

const Loader = (Component: any) => (props: any) => {
  const LazyComponent = lazy(Component);
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default Loader;
