import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectors as selectorAuth } from "../store/auth";
import { selectors as selectorApp } from "../store/app";
import { checkPermissons } from "../utils/auth";
import { RouteConfigs } from "./route.config";
import { CONFIG_APP } from "../configs/common";

export const GuardAuth = ({ children }): JSX.Element => {
  const isLoggedIn = useSelector(selectorAuth.userSelector);
  const currentUser = useSelector(selectorApp.currentUserSelector);
  const location = useLocation();

  const canAccessRoute = useMemo(() => {
    let currentPath = location.pathname;
    if (RouteConfigs.ignorePermission.includes(currentPath)) {
      return true;
    }

    let routeObject = RouteConfigs?.permissionRoute?.find(
      (item: any) => currentPath === `/${item.path}`
    );
    return checkPermissons(
      currentUser,
      routeObject?.module || '',
      routeObject?.permissions || []
    );
  }, [currentUser, location.pathname]);

  let redricet: JSX.Element = (
    <Navigate to="/404" replace state={{ path: location.pathname }} />
  );

  if (CONFIG_APP.CURRENT_STATE_APP === "maintain") {
    redricet = (
      <Navigate
        to={"/maintenance"}
        replace
        state={{ path: location.pathname }}
      />
    );
  } else {
    if (isLoggedIn) {
      redricet = canAccessRoute ? (
        children
      ) : (
        <Navigate to={"/404"} replace state={{ path: location.pathname }} />
      );
    } else {
      redricet = (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
      );
    }
  }
  return redricet;
};
