import { useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import uploadService from "../services/upload.service";

export const useUploadFile = () => {
  const [files, setFiles] = useState([]);
  const fileRef = useRef(null);

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File(
      [theBlob as any], // cast as any
      fileName,
      {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      }
    );
  };

  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const handleChangeFile = async (e: any) => {
    let filesInput = e.target.files;

    let listCompressedFile = [];
    let processCompressFile = new Promise((resolve, reject) => {
      Array.from(filesInput).forEach(async (item: any, index, array) => {
        let fileBlob = await imageCompression(item, options);
        let compressedFile = blobToFile(fileBlob, item.name);
        listCompressedFile.push(compressedFile);
        if (index === array.length - 1) resolve(listCompressedFile);
      });
    });

    processCompressFile
      .then(async (file) => {
        const res = await uploadService.uploadFileAction(file);

        if (res) {
          setFiles([...files, ...res]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const hanldeDeleteFile = async (fileId: any) => {
    const res = await uploadService.deleteFile(fileId);
    if (res) {
      let fileFiltered = files.filter((file: any) => file.id !== fileId);
      setFiles(fileFiltered);
    }
  };

  return [files, fileRef, setFiles, handleChangeFile, hanldeDeleteFile];
};
