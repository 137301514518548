import axios from "./abstract.service";
import { User } from "../models/user";
import { toast } from "react-toastify";

const getMe = (): any => {
  return axios
    .get(`users/me`)
    .then((response: any) => {
      return response.data?.data;
    })
    .catch((err) => {
      // throw err;
    });
};

const getUserInfo = (id: string): any => {
  return axios
    .get(`users/${id}`)
    .then((response: any) => {
      return response.data?.data;
    })
    .catch((err) => {
      // throw err;
    });
};

const getListUser = (params: any): any => {
  return axios
    .get(`users`, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      // throw err;
    });
};

const createUser = (body: User): any => {
  delete body.id;
  return axios
    .post(`users`, body)
    .then((response: any) => {
      toast.success("Thực hiện thành công");
      return response.data?.id;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Tạo user thất bại");
      }
      return Promise.reject(err);
    });
};

const updateUser = (id: any, bodyData: any): any => {
  delete bodyData.id;
  delete bodyData.username;

  return axios
    .put(`users/${id}`, bodyData)
    .then((response: any) => {
      toast.success("Thực hiện thành công");
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Cập nhật user thất bại");
      }
      return Promise.reject(err);
    });
};

const resetPassword = (bodyData: any): any => {
  return axios
    .post(`users/reset-password`, bodyData)
    .then((response: any) => {
      toast.success("Thực hiện thành công");
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Cập nhật mật khẩu thất bại");
      }
      return Promise.reject(err);
    });
};

const changePassword = (body: any): any => {
  return axios
    .post(`users/change-password`, body)
    .then((response: any) => {
      return response.data?.data?.id;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Cập nhật mật khẩu thất bại");
      }
      return Promise.reject(err);
    });
};

const deleteUser = (id: any): any => {
  return axios
    .delete(`users/${id}`)
    .then((response: any) => {
      toast.success("Xoá thành công");
      return response.data?.data?.id;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        toast.error("Xoá user thất bại");
      }
      return Promise.reject(err);
    });
};

const userService = {
  getMe,
  getUserInfo,
  getListUser,
  createUser,
  updateUser,
  resetPassword,
  deleteUser,
  changePassword,
};

export default userService;
