import Loader from 'components/Loader';


// Status
const Status404 = Loader(() => import('modules/Status/Status404'));
const Status500 = Loader(() => import('modules/Status/Status500'));
const StatusComingSoon = Loader(() => import('modules/Status/ComingSoon'));
const StatusMaintenance = Loader(() => import('modules/Status/Maintenance'));

export const statusPath = [
  {
    path: '404',
    element: <Status404 />
  },
  {
    path: '500',
    element: <Status500 />
  },
  {
    path: 'maintenance',
    element: <StatusMaintenance />
  },
  {
    path: 'coming-soon',
    element: <StatusComingSoon />
  },
];
