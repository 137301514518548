import { MenuItems } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const housesMenu: MenuItems[] = [
    {
      id: '0',
      heading: '',
      module: "REAL_ESTATE",
      permissions: ["MENU"],
      items: [
        {
          id: '0_1',
          parent: '0',
          name: 'houses',
          link: '/houses',
          icon: HomeWorkIcon,
          module: "REAL_ESTATE",
          permissions: ["MENU"],
        }
      ],
    }
  ];
  
  export default housesMenu;
  