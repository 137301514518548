import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectors as selectorApp } from "store/app";
import { CONFIG_APP } from "../../configs/common";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        align-items: center;
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const VersionBadge = styled(Box)(
  ({ theme }) => `
        color: ${theme.colors.secondary.light};
        text-align: center;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(10)};
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(14)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);
  return (
    <LogoWrapper to="/houses">
      <Box sx={{ paddingLeft: 1, marginTop: 1 }}>
        <img
          src="/static/images/placeholders/logo/logo.jpg"
          width={30}
          height={30}
        />
      </Box>

        {isOpenSidebar ? (
          <LogoTextWrapper>
            <LogoText>{CONFIG_APP.COMPANY_NAME || "Admin"}</LogoText>
              <VersionBadge> Kết nối giá trị thực</VersionBadge>
          </LogoTextWrapper>
        ) : null}
     
    </LogoWrapper>
  );
}

export default Logo;
