import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar, Box, Card, Grid, IconButton, styled } from "@mui/material";
import FormFmk from "components/Form/FormBase";
import TextFieldFmik from "components/Form/TextField";
import * as Yup from "yup";
import userService from "services/user.service";
import { emailRegex, ERROR_MESSAGE } from "constants/validation";
import { stringAvatar } from "../../utils/common";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import { useUploadFile } from "../../hooks/useUploadFile";
import { actions as actionApp } from "store/app";
import { useDispatch } from "react-redux";

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    order-radius: 50%;
    box-shadow: unset;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
      border-radius: 50%;
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(0.5)};
    right: -${theme.spacing(0.5)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const validationSchema = Yup.object({
  username: Yup.string().nullable().required(ERROR_MESSAGE.REQUIRED),
  fullName: Yup.string().nullable().required(ERROR_MESSAGE.REQUIRED),
  email: Yup.string().nullable().matches(emailRegex, ERROR_MESSAGE.EMAIL),
});

export default function EditProfile({ open, handleClose, userInfo }) {
  const dispatch = useDispatch();
  const [files, fileRef, setFiles, handleChangeFile]: any = useUploadFile();
  const onCreateOrUpdateData = async (data: any) => {
    let bodyData = {
      ...data,
      avatar: files.length ? files[0]?.fileUrl: userInfo?.avatar
    };
   delete bodyData.role;
    let res = await userService.updateUser(userInfo?.id, bodyData)

    if (res) {
      dispatch(actionApp.getCurrentUser({}));
      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Thông tin cá nhân</DialogTitle>

        <FormFmk
          initValue={userInfo}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onCreateOrUpdateData}
        >
          <DialogContent>
            <Box
              sx={{ marginBottom: 3, display: "flex", alignItems: "center" }}
            >
              <AvatarWrapper>
                <Avatar {...stringAvatar(userInfo?.fullName)} variant="rounded" alt={userInfo?.fullName} src={files[0]?.fileUrl || userInfo?.avatar} />
                <ButtonUploadWrapper>
                  <Input
                    ref={fileRef}
                    accept="image/*"
                    id="icon-button-file"
                    name="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      component="span"
                      color="primary"
                      onClick={() => (fileRef.current.value = "")}
                    >
                      <UploadTwoToneIcon />
                    </IconButton>
                  </label>
                </ButtonUploadWrapper>
              </AvatarWrapper>
            </Box>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  required
                  id="username"
                  label="Tài khoản"
                  name="username"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled={userInfo?.id ? true : false}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  required
                  id="fullName"
                  label="Họ tên"
                  name="fullName"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  id="email"
                  label="Email"
                  name="email"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  id="phone"
                  label="Phone"
                  name="phone"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  id="zalo"
                  label="Zalo"
                  name="zalo"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldFmik
                  id="department"
                  label="Đơn vị"
                  name="department"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Đóng
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Lưu
            </Button>
          </DialogActions>
        </FormFmk>
      </Dialog>
    </div>
  );
}
