import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { withTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import FormFmk from "components/Form/FormBase";
import TextFieldFmik from "components/Form/TextField";
import * as Yup from "yup";
import userService from "services/user.service";
import { ERROR_MESSAGE, passwordRegex } from "../../constants/validation";
import { useDispatch } from "react-redux";
import { actions as actionAuth } from "store/auth";

const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .nullable()
    .required(ERROR_MESSAGE.REQUIRED)
    .matches(passwordRegex, ERROR_MESSAGE.PASSWORD),
  newPassword: Yup.string()
    .nullable()
    .required(ERROR_MESSAGE.REQUIRED)
    .matches(passwordRegex, ERROR_MESSAGE.PASSWORD),
  confirmPassword: Yup.string()
    .nullable()
    .required(ERROR_MESSAGE.REQUIRED)
    .oneOf([Yup.ref("newPassword"), null], "Mật khẩu chưa khớp"),
});

function ChangePassword(props: any) {
  const dispatch = useDispatch();
  const { onClose, selectedValue, open, t } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onChange = async (data: any) => {
    let res = await userService.changePassword(data);

    if (res) {
      dispatch(actionAuth.logout(null));
    }
  };
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} onClose={handleClose} open={open}>
      <DialogTitle>{t("common.ChangePassword")}</DialogTitle>

      <FormFmk
        initValue={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onChange}
      >
        <DialogContent>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextFieldFmik
                required
                id="currentPassword"
                label="Mật khẩu hiện tại"
                name="oldPassword"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFmik
                required
                id="password"
                label="Mật khẩu mới"
                name="newPassword"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFmik
                id="passwordConfirmation"
                label="Xác nhận mật khẩu mới"
                name="confirmPassword"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Lưu
          </Button>
        </DialogActions>
      </FormFmk>
    </Dialog>
  );
}

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(ChangePassword);
