import { ReactNode } from 'react';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import userMenu from 'modules/Users/configs/menu';
import dashBoardMenu from 'modules/Dashboard/configs/menu';
import statusMenu from 'modules/Status/configs/menu';
import componentMenu from 'modules/ComponentsPage/configs/menu';
import housesMenu from 'modules/Houses/configs/menu';

export interface MenuItem {
  id: string
  parent: string;
  name: string;
  module: string;
  permissions: any[];
  items?: MenuItem[];
  link?: string;
  icon?: ReactNode;
  badge?: string;
}

export interface MenuItems {
  id: string,
  heading: string;
  module: string
  permissions: any[]
  items: MenuItem[];
}

const menuItems: MenuItems[] = [
  {
    id: '0',
    heading: '',
    module: "auth",
    permissions: ["callback"],
    items: [
      {
        id: '0_1',
        parent: '0',
        name: 'home',
        link: '/home',
        icon: BackupTableIcon,
        module: "auth",
        permissions: ["callback"],
      }
    ],
  },
  ...dashBoardMenu,
  ...housesMenu,
  ...userMenu,
  ...statusMenu,
  ...componentMenu
];

export default menuItems;
