import React from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Hidden, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { SidebarContext } from "contexts/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import HeaderUserbox from "./Userbox";
import Logo from "components/Logo";
// import ChangeTheme from "./Buttons/ChangeTheme";
// import HeaderNotifications from "./Buttons/Notifications";

import {
  actions as actionApp,
  selectors as selectorApp,
} from "../../../store/app";
import { BREAK_POINT_SCREEN } from "constants/common";
// import HeaderSearch from "./Buttons/Search";

const HeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<any>(({ theme, open, screenSize }) => ({
  height: theme.header.height,
  color: theme.header.textColor,
  padding: theme.spacing(0, 2),
  right: 0,
  zIndex: 5,
  backgroundColor: theme.header.background,
  boxShadow: theme.header.boxShadow,
  position: "fixed",
  left:
    screenSize.winWidth > BREAK_POINT_SCREEN ? theme.sidebar.width : "unset",
  justifyContent: "space-between",
  width: screenSize.winWidth < BREAK_POINT_SCREEN ? "100%" : "unset",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    left: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(100% - ${theme.sidebar.width}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);
  const currScreenSize = useSelector(selectorApp.screenSizeSelector);
  const dispatch = useDispatch();

  const collapseSideBar = () => {
    dispatch(actionApp.toggleCollapseSideBar());
  };
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      open={!isOpenSidebar}
      screenSize={currScreenSize}
    >
      <Box display="flex" alignItems="center">
        <Hidden lgUp>
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar} size="small">
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <Logo />
        </Hidden>
        <Hidden lgDown>
          <IconButton color="primary" onClick={collapseSideBar} size="small">
            <MenuTwoToneIcon fontSize="small" />
          </IconButton>
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        {/* <HeaderSearch /> */}
        <HeaderUserbox />
        {/* <HeaderNotifications /> */}
        {/* <ChangeTheme /> */}
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
