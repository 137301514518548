import _ from 'lodash';
// import NProgress from 'nprogress';

const fn = ({ actions, process = (p: any) => { }, ...options }) => {
  return (payload: any) => async (dispatch: any, getState: any, objDeps: any) => {
    const executeState = async () => {
      const { startAction, successAction, failedAction } = actions;
      // NProgress.start();
      // NProgress.configure({showSpinner: false, speed: 800})

      if (_.isFunction(startAction)) {
        dispatch(startAction(payload));
      }

      try {
        const result: any = await process({ payload, dispatch, getState });
        // if(result !== undefined) {
        //   NProgress.done();
        // }
        if (_.isFunction(successAction)) {
          dispatch(successAction({ result, params: payload }));
        }

        let onSuccess: string = null;
        if (options && options.onSuccess) {
          ({ onSuccess } = options);
        }

        if (_.isFunction(onSuccess)) {
          onSuccess({ dispatch, getState, params: payload, result, ...objDeps });
        }
        
        return result;
      } catch (error) {
        // NProgress.done();
        if (_.isFunction(failedAction)) {
          dispatch(failedAction({ error }));
        }
      }
    };

    return executeState();
  };
};

export default fn;
